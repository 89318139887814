<template>
    <div class="inventory-list">
      <b-card title="Inventario Total">
        <b-form inline class="mb-3">
          <b-form-group label="Buscar por nombre, SKU, EAN o fabricante:" label-for="input-search-name">
            <b-form-input id="input-search-name" v-model="searchTerm" placeholder="Buscar" />
          </b-form-group>
  
          <!-- Filtro por estado del artículo -->
          <b-form-group label="Filtrar por estado:" class="ml-3">
            <b-form-select v-model="filterStatus" :options="statusOptions" />
          </b-form-group>
  
          <!-- Filtro por categoría -->
          <b-form-group label="Filtrar por categoría:" class="ml-3">
            <b-form-select v-model="filterCategory" :options="categoryOptions" />
          </b-form-group>
  
          <!-- Filtro por bodega -->
          <b-form-group label="Filtrar por bodega:" class="ml-3">
            <b-form-select v-model="filterWarehouse" :options="warehouseOptions" />
          </b-form-group>
  
          <!-- Checkbox para habilitar el filtro de fecha -->
          <b-form-group class="ml-3">
            <b-form-checkbox v-model="enableDateFilter" name="enable-date-filter">
              Habilitar filtro por fecha
            </b-form-checkbox>
          </b-form-group>
  
          <!-- Mostrar select de opciones solo si el filtro de fecha está habilitado -->
          <b-form-group class="ml-3" v-if="enableDateFilter">
            <b-form-select v-model="filterOption" :options="filterDateOptions" />
          </b-form-group>
  
          <!-- Campos de fecha personalizados -->
          <b-form-group class="ml-3 mt-2" label="Fecha personalizada" v-if="filterOption === 'custom' && enableDateFilter">
            <b-form-input class="ml-1" type="date" v-model="customDate" />
          </b-form-group>
  
          <!-- Campos de rango de fechas -->
          <b-form-group class="ml-3 mt-2" label="Rango de fechas" v-if="filterOption === 'range' && enableDateFilter">
            <b-form-input class="ml-1" type="date" v-model="startDate" placeholder="Fecha de inicio" />
            <b-form-input class="ml-1" type="date" v-model="endDate" placeholder="Fecha de fin" />
          </b-form-group>
        </b-form>
  
        <!-- Tabla de inventario con columna de estado -->
        <b-table striped hover :items="filteredItems" :fields="fields">
          <template #cell(status)="data">
            <span v-if="data.item.status === 'operativo'" class="text-success">
              <i class="fas fa-check-circle"></i>
            </span>
            <span v-if="data.item.status === 'mermado'" class="text-warning">
              <i class="fas fa-minus-circle"></i>
            </span>
            <span v-if="data.item.status === 'vencido'" class="text-danger">
              <i class="fas fa-times-circle"></i>
            </span>
          </template>
  
          <!-- Mostrar fabricante -->
          <template #cell(manufacturer)="data">
            {{ data.item.manufacturer }}
          </template>
  
          <template #cell(category)="data">
            {{ data.item.category }}
          </template>
          
          <template #cell(warehouse)="data">
            {{ data.item.warehouse }}
          </template>
  
          <template #cell(quantity)="data">
            <span v-if="filterWarehouse === 'todos'">
              {{ getTotalQuantity(data.item) }}
            </span>
            <span v-else>
              {{ getWarehouseQuantity(data.item, filterWarehouse) }}
            </span>
          </template>
  
          <!-- Select para Acciones -->
          <template #cell(actions)="data">
            <b-form-select @change="handleActionChange(data.item.id, $event)">
              <option disabled value="">Selecciona una acción</option>
              <option value="details">Detalles</option>
              <option value="edit">Editar</option>
              <option value="adjust">Ajustar</option>
              <option value="count">Recuento Físico</option>
              <option value="delete">Eliminar</option>
            </b-form-select>
          </template>
        </b-table>
      </b-card>
      <b-button @click="addItem" variant="success" class="mt-3">Agregar Artículo</b-button>
  
      <!-- Modal de Confirmación de Eliminación -->
      <b-modal id="confirm-delete-modal" title="Confirmación de Eliminación" @ok="confirmDelete">
        <p>Está seguro de eliminar este artículo?</p>
      </b-modal>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        items: [],
        searchTerm: '', // Buscar por nombre, SKU, EAN o fabricante
        filterStatus: 'todos',
        filterCategory: 'todos',
        filterWarehouse: 'todos',
        enableDateFilter: false,
        filterOption: 'today',
        customDate: '',
        startDate: '',
        endDate: '',
        itemToDelete: null, // Para manejar el artículo que se va a eliminar
        fields: [
          { key: 'status', label: 'Estado' },
          { key: 'name', label: 'Producto' },
          { key: 'manufacturer', label: 'Fabricante' }, // Nuevo campo: Fabricante
          { key: 'quantity', label: 'Cantidad' },
          { key: 'ean', label: 'EAN' },
          { key: 'sku', label: 'SKU' },
          { key: 'lote', label: 'Lote' },
          { key: 'expiration', label: 'Vencimiento' },
          { key: 'category', label: 'Categoría' },
          { key: 'warehouse', label: 'Bodega' },
          { key: 'actions', label: 'Acciones' }
        ],
        statusOptions: [
          { value: 'todos', text: 'Todos' },
          { value: 'operativo', text: 'Operativo' },
          { value: 'mermado', text: 'Mermado' },
          { value: 'vencido', text: 'Vencido' }
        ],
        categoryOptions: [
          { value: 'todos', text: 'Todas' },
          { value: 'farmacos', text: 'Fármacos' },
          { value: 'ministeriales', text: 'Arsenal Ministeriales' },
          { value: 'ocasionales', text: 'Arsenal Ocasionales' },
          { value: 'controlados', text: 'Arsenal Controlados' },
          { value: 'insumos', text: 'Insumos' }
        ],
        warehouseOptions: [
          { value: 'todos', text: 'Todas' },
          { value: 'full_space', text: 'Bodega Full Space' },
          { value: 'kardex_hospitalizado', text: 'Bodega Kardex Hospitalizado' },
          { value: 'kardex_ambulatorio', text: 'Bodega Kardex Ambulatorio' },
          { value: 'recetas_alta', text: 'Bodega Recetas de Alta' },
          { value: 'mermas', text: 'Bodega Mermas' },
          { value: 'cuarentena', text: 'Bodega Cuarentena' }
        ],
        filterDateOptions: [
          { value: 'today', text: 'Hasta hoy' },
          { value: 'custom', text: 'Fecha personalizada' },
          { value: 'range', text: 'Rango de fechas' }
        ]
      };
    },
    computed: {
      filteredItems() {
        const today = new Date().toISOString().split('T')[0];
        const defaultDate = new Date();
        defaultDate.setDate(defaultDate.getDate() + 7);
        const sevenDaysFromToday = defaultDate.toISOString().split('T')[0];
  
        return this.items.filter(item => {
          const matchesSearch = this.searchTerm
            ? (
                (item.name || '').toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                (item.sku || '').toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                (item.ean || '').toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                (item.manufacturer || '').toLowerCase().includes(this.searchTerm.toLowerCase()) // Buscar por fabricante
              )
            : true;
  
          let matchesStatus = this.filterStatus === 'todos'
            ? true
            : item.status === this.filterStatus;
  
          let matchesCategory = this.filterCategory === 'todos'
            ? true
            : item.category === this.filterCategory;
  
          let matchesWarehouse = this.filterWarehouse === 'todos'
            ? true
            : item.warehouses.some(w => w.warehouse === this.filterWarehouse);
  
          let matchesExpiration = true;
          if (this.enableDateFilter) {
            if (this.filterOption === 'today') {
              matchesExpiration = item.expiration <= today;
            } else if (this.filterOption === 'custom') {
              const filterDate = this.customDate || sevenDaysFromToday;
              matchesExpiration = item.expiration <= filterDate;
            } else if (this.filterOption === 'range') {
              matchesExpiration =
                this.startDate &&
                this.endDate &&
                this.startDate <= this.endDate &&
                item.expiration >= this.startDate &&
                item.expiration <= this.endDate;
            }
          }
  
          return matchesSearch && matchesStatus && matchesCategory && matchesWarehouse && matchesExpiration;
        });
      }
    },
    methods: {
      getTotalQuantity(item) {
        if (item.warehouses && Array.isArray(item.warehouses)) {
          return item.warehouses.reduce((total, warehouse) => {
            return total + parseFloat(warehouse.quantity || 0);
          }, 0);
        }
        return 0;
      },
      getWarehouseQuantity(item, warehouseName) {
        if (item.warehouses && Array.isArray(item.warehouses)) {
          const warehouse = item.warehouses.find(w => w.warehouse === warehouseName);
          return warehouse ? warehouse.quantity : 0;
        }
        return 0;
      },
      handleActionChange(id, value) {
        switch (value) {
          case 'details':
            this.viewDetails(id);
            break;
          case 'edit':
            this.editItem(id);
            break;
          case 'adjust':
            this.adjustItem(id);
            break;
          case 'count':
            this.countItem(id);
            break;
          case 'delete':
            this.itemToDelete = id;
            this.$bvModal.show('confirm-delete-modal');
            break;
          default:
            break;
        }
      },
      viewDetails(id) {
        this.$router.push({ name: 'inventory-detail', params: { id } });
      },
      editItem(id) {
        this.$router.push({ name: 'edit-inventory', params: { id } });
      },
      adjustItem(id) {
        this.$router.push({ name: 'adjust-inventory', params: { id } });
      },
      countItem(id) {
        this.$router.push({ name: 'count-inventory', params: { id } });
      },
      deleteItem(id) {
        this.items = this.items.filter(item => item.id !== id);
        localStorage.setItem('inventoryItems', JSON.stringify(this.items));
      },
      confirmDelete() {
        this.deleteItem(this.itemToDelete);
        this.itemToDelete = null;
      },
      addItem() {
        this.$router.push({ name: 'add-inventory' });
      },
      loadItems() {
        const storedItems = JSON.parse(localStorage.getItem('inventoryItems')) || [];
        this.items = storedItems;
      }
    },
    mounted() {
      this.loadItems();
    }
  };
  </script>
  
  <style scoped>
  .inventory-list {
    margin-top: 20px;
  }
  .text-success i {
    color: green;
  }
  .text-warning i {
    color: orange;
  }
  .text-danger i {
    color: red;
  }
  </style>
  