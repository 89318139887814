<template>
    <div class="container">
      <b-card title="Gestión de Trazabilidad" class="mb-3">
        <b-card-text class="small">Ante un recall o simplemente para obtener info de un Producto Médico</b-card-text>
        <b-form-input
          v-model="searchQuery"
          placeholder="Buscar por Dispositivo, Lote o Serial Number"
          class="mb-3"
        ></b-form-input>
  
        <b-table :items="filteredDevices" :fields="fields">
          <!-- Columna para Dispositivo -->
          <template #cell(Dispositivo)="data">
            {{ data.item.details.Dispositivo }}
          </template>
          
          <!-- Columna para Lote -->
          <template #cell(Lote)="data">
            {{ data.item.details.LN }}
          </template>
  
          <!-- Columna para Serial Number -->
          <template #cell(SN)="data">
            {{ data.item.details.SN }}
          </template>
  
          <!-- Columna para Estado -->
          <template #cell(status)="data">
            <span :class="data.item.status === 'active' ? 'text-success' : 'text-danger'">
              {{ data.item.status }}
            </span>
          </template>
  
          <!-- Columna para Location/Zone -->
          <template #cell(location)="data">
            <div v-if="data.item.location">
              {{ data.item.location.name }} / {{ data.item.location.zoneName }}
            </div>
            <div v-else>
              Sin ubicación
            </div>
          </template>
  
          <!-- Columna para acciones -->
          <template #cell(actions)="data">
            <b-button @click="openDetailsModal(data.item)" variant="info">Ver</b-button>
          </template>
        </b-table>
      </b-card>
  
      <!-- Modal para los detalles del dispositivo -->
      <b-modal v-model="showDetailsModal" title="Detalles del Dispositivo Médico">
        <h5 class="mt-1"><strong>Información de temperatura</strong></h5>
        <!-- Gráfico de temperaturas -->
        <apexchart type="line" :options="chartOptions" :series="temperatureSeries"></apexchart>
        
        <!-- Termómetro -->
        <vuejs-thermometer :value="lastTemperature" :min="-40" :max="80"></vuejs-thermometer>
        <p>Última temperatura: <strong>{{ lastTemperature }} °C / {{ lastCommunicationDate }}</strong></p>
        <hr>
        <!-- Historial de traspasos -->
        <h5 class="mt-1"><strong>Historial de traspasos internos</strong></h5>
        <Timeline :items="ownerHistory" />
      </b-modal>
    </div>
  </template>
  
  <script>
  import apexchart from 'vue-apexcharts';
  import VuejsThermometer from 'vuejs-thermometer';
  import Config from "@/../config.json";
  import Timeline from "../../components/misc/Timeline.vue";
  
  export default {
    components: {
      apexchart,
      VuejsThermometer,
      Timeline
    },
    data() {
      return {
        searchQuery: '',
        showDetailsModal: false,
        selectedDevice: null,
        fields: [
          { key: 'Dispositivo', label: 'Dispositivo' },
          { key: 'Lote', label: 'Lote' },
          { key: 'SN', label: 'Serial Number' },
          { key: 'status', label: 'Estado' },
          { key: 'location', label: 'Location/Zone' },
          { key: 'actions', label: 'Acciones' }
        ],
        devices: [],
        temperatureSeries: [],
        lastTemperature: null,
        lastCommunicationDate: null,
        ownerHistory: [],
        chartOptions: {
          chart: {
            id: 'temperature-chart',
            toolbar: {
              show: false
            }
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: false,
              format: 'dd MMM HH:mm'
            }
          },
          title: {
            text: 'Historial de Temperatura',
            align: 'center'
          }
        }
      };
    },
    computed: {
      filteredDevices() {
        const query = this.searchQuery.toLowerCase();
        return this.devices.filter(device =>
          device.details.Dispositivo.toLowerCase().includes(query) ||
          device.details.LN.toLowerCase().includes(query) ||
          device.details.SN.toLowerCase().includes(query)
        );
      }
    },
    methods: {
      parseTemperatureTimestamp(timestampStr) {
        // Reemplazar espacio por 'T', truncar a milisegundos y añadir 'Z' al final para indicar UTC
        let isoString = timestampStr.replace(' ', 'T').substring(0, 23) + 'Z';
        return new Date(isoString);
      },
      async openDetailsModal(device) {
        this.selectedDevice = device;
        this.showDetailsModal = true;
  
        const token = localStorage.getItem('token');
  
        // Llamada al backend para obtener los datos de temperatura
        const temperatureResponse = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/getAssetTemperature`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            assetuuid: device.id
          })
        });
  
        const temperatureData = await temperatureResponse.json();
        if (temperatureResponse.ok) {
          // Procesar los datos para el gráfico y el termómetro
          const temperatures = temperatureData.temperature_data.map(entry => ({
            x: this.parseTemperatureTimestamp(entry.timestamp),  // Usar la función actualizada
            y: parseFloat(entry.temperature)
          }));
  
          this.temperatureSeries = [
            {
              name: "Temperatura",
              data: temperatures
            }
          ];
  
          // Extraer la última temperatura y la última comunicación
          const lastEntry = temperatureData.temperature_data[temperatureData.temperature_data.length - 1];
          this.lastTemperature = parseFloat(lastEntry.temperature);
  
          // Obtener la fecha correctamente
          const lastEntryDate = this.parseTemperatureTimestamp(lastEntry.timestamp);
          this.lastCommunicationDate = lastEntryDate.toLocaleString('es-CL', { 
            timeZone: 'America/Santiago',
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit'
          });
        } else {
          console.error(temperatureData.error);
        }
  
        // Llamada al backend para obtener el historial de traspasos
        const ownershipResponse = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/getAssetOwnershipHistory`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
            assetuuid: device.id
            })
        });

        const ownershipData = await ownershipResponse.json();
        if (ownershipResponse.ok) {
            // Procesar y formatear las fechas de los eventos
            this.ownerHistory = ownershipData.ownership_history.map(event => {
            const timestamp = parseInt(event.datetime, 10) * 1000;
            const eventDate = new Date(timestamp);

            const formattedDate = eventDate.toLocaleString('es-CL', {
                timeZone: 'America/Santiago',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });

            return {
                ...event,
                formattedDate
            };
            });
        } else {
            console.error(ownershipData.error);
        }
      },
      async fetchDevices() {
        const token = localStorage.getItem('token');
  
        // Llamada al endpoint para obtener los dispositivos y su estado
        const response = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/get_wiliot_assets_state`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
  
        const devices = await response.json();
        if (response.ok) {
          this.devices = devices;
        } else {
          console.error(devices.error);
        }
      }
    },
    mounted() {
      // Al montar el componente, se cargan los dispositivos
      this.fetchDevices();
    }
  };
  </script>
  
  <style scoped>
  /* Estilos personalizados, si es necesario */
  </style>
  
  