<template>
  <div class="container">
    <b-row class="mt-1">
      <button @click="goToMyAssetList" class="btn btn-primary mt-2 text-center">Ver mis listados creados</button>
    </b-row>
    <b-row class="mt-1">
      <h4 v-if="invoiceData.length === 0">Crea una nueva lista de dispositivos médicos</h4>
      <h4 v-else>Continúa creando una lista</h4>
    </b-row>
    <b-row v-if="invoiceData.length === 0">
      <b-card-text>Tienes 3 formas de comenzar:</b-card-text>
    </b-row>
    <b-row v-if="invoiceData.length === 0">
      <b-card class="mt-2 mb-3">
        <b-col md="12" lg="12" xl="12" class="col-xxl-12">
          <b-tabs v-model="selectedTab" class="nav-tab-custom" content-class="mt-3">
            <b-tab title="Subir Documento" active>
              <div v-if="selectedTab === 0 && !invoiceData.length">
                <b-card-text class="small">Tienes una factura en pdf. Súbela y crea la lista desde los dispositivos que aparecen.</b-card-text>
                <b-card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive" style="background-color: #f2f2f2;">
                  <template #header>
                    <h4 class="card-title">Subir Documento</h4>
                    <p class="card-category">Cargar un documento pdf</p>
                  </template>
                  <div class="form-group text-center">
                    <input type="file" @change="onFileChange" accept="image/*,.pdf" class="form-control mb-2" />
                  </div>
                </b-card>
              </div>
            </b-tab>
            <b-tab title="Tomar Fotografía">
              <!-- Selección de cámara -->
              <div v-if="selectedTab === 1 && !invoiceData.length" class="text-center mb-2">
                <label for="videoSource">Seleccionar Cámara:</label>
                <select id="videoSource" @change="getStream" v-model="selectedVideoSource" class="form-control d-inline-block w-auto">
                  <option v-for="device in videoDevices" :key="device.deviceId" :value="device.deviceId">
                    {{ device.label || `Cámara ${device.deviceId}` }}
                  </option>
                </select>
              </div>

              <!-- Vista de cámara--> 
              <video ref="video" class="video-fluid mt-2" autoplay playsinline></video>
              <canvas ref="canvas" width="1280" height="720" style="display:none;"></canvas>
              <div class="card-footer text-center">
                <button @click="takePhoto" class="btn btn-primary mt-2 text-center">Tomar Fotografía</button>
                
                <div v-if="loading" class="spinner-border text-primary ml-2" role="status">
                  <span class="sr-only">Analizando...</span>
                </div>
              
              </div>
            </b-tab>
            <b-tab title="Crear Anexo de Activos">
              <div v-if="selectedTab === 2">
                <b-card-text class="small">No tienes ningún documento digital ni en papel. Comienza desde cero agregando los dispositivos.</b-card-text>
                <b-card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive" style="background-color: #f2f2f2;">
                  <template>
                    <h4 class="card-title">Crear Lista de Dispositivos Médicos</h4>
                    <p class="card-category">Añadir nuevo DM a la lista</p>
                  </template>
                  <div class="form-group text-center">
                    <button @click="addNewItem" class="btn btn-success mt-2 text-center">Crear nuevo dispositivo</button>
                  </div>
                </b-card>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-card>
    </b-row>
    <b-row v-else>
      <b-card-text>Si deseas comenzar una lista nueva, presiona el botón </b-card-text>
      <div class="text-left ml-2">
        <b-button @click="createNewAnexo" variant="primary" class="mb-3">Crear nueva lista</b-button>
      </div>
      <!--
      <div class="qr-general text-center w-100">
        <vue-qrcode :text="generateGeneralQRCodeContent()" :size="200"></vue-qrcode>
      </div>
      -->
    </b-row>

    <!-- Tabla de Anexo de Activos -->
    <div class="row">
      <div class="col-12" v-if="invoiceData.length && !loading">
        <b-card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
          <template #header>
            <h4 class="card-title">Lista de dispositivos médicos</h4>
            <div class="form-group text-center">
              <button @click="addNewItem" class="btn btn-success mt-2 text-center">Añadir nuevo registro</button>
            </div>
            <!--
            <p class="card-category">Items extraídos del documento</p>
            -->
          </template>
          <div class="table-responsive">
            <table class="table table-hover table-striped">
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>SN</th>
                  <th>LN</th>
                  <th>FV</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in invoiceData" :key="index">
                  <td>
                    <input v-if="item.editing" type="text" v-model="item.Dispositivo" class="form-control" />
                    <span v-else>{{ item.Dispositivo }}</span>
                  </td>
                  <td>
                    <input v-if="item.editing" type="number" v-model="item.Cantidad" @input="validateQuantity(item)" class="form-control" />
                    <span v-else>{{ item.Cantidad }}</span>
                  </td>
                  <td>
                    <input v-if="item.editing && item.Cantidad == 1" type="text" v-model="item.SN" class="form-control" />
                    <span v-else>{{ item.SN }}</span>
                  </td>
                  <td>
                    <input v-if="item.editing" type="text" v-model="item.LN" class="form-control" />
                    <span v-else>{{ item.LN }}</span>
                  </td>
                  <td>
                    <vuejs-datepicker v-if="item.editing" v-model="item.FV" :language="es" format="dd-MM-yyyy" class="form-control" />
                    <span v-else @click="editDate(item)">{{ formatDate(item.FV) }}</span>
                  </td>
                  <td>
                    <div class="d-flex">
                      <button v-if="!item.editing && !item.assetuuid" @click="editItem(item)" class="btn btn-warning">Editar</button>
                      <button v-if="item.editing" :disabled="!canSave(item)" @click="saveItem(item)" class="btn btn-primary ml-1">Guardar</button>
                      <button v-if="!item.editing && isComplete(item) && !item.assetuuid" @click="enrollItem(item, index)" class="btn btn-success ml-1">Enrolar</button>
                      <button v-if="item.assetuuid" @click="showDetails(item)" class="btn btn-info ml-1">
                        Detalle <i class="fa fa-qrcode"></i>
                      </button>
                      <button @click="deleteItem(index)" class="btn btn-danger ml-1">
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card>
      </div>

      <!-- Preview del Documento -->
      <div class="col-lg-6 col-md-12" v-if="preview && !invoiceData.length">
        <b-card class="strpied-tabled-with-hover ml-5" body-classes="table-full-width table-responsive" style="background-color: #f2f2f2;">
          <template #header>
            <h4 class="card-title">Resultado de carga</h4>
            <p class="card-category">Documento cargado</p>
          </template>
          <div class="form-group text-center">
            <!-- Mostrar la imagen previa si es una imagen -->
            <img v-if="isImage(preview)" :src="preview" class="img-fluid mt-2" />

            <!-- Botón de analizar documento, oculto si está cargando -->
            <div v-if="!loading" class="card-footer text-center">
              <button @click="uploadFile" class="btn btn-success mt-2 text-center">Analizar Documento</button>
            </div>
            <!-- Mostrar el GIF de carga cuando está cargando y no es una imagen -->
            <div v-if="loading && !isImage(preview)" class="spinner-container">
              <img :src="spinnerImage" alt="Cargando..." />
            </div>
          </div>
        </b-card>

      </div>
    </div>

    <!-- Modal para ver detalles del activo -->
    <b-modal id="details-modal" v-model="showModal" title="Detalle de los DM's" @hide="onModalClose">
      <!-- Contenido de listado de activos (se oculta cuando se está pareando con Tag) -->
      <div v-show="!selectCameraMode">
        <small>Para cada DM puedes imprimir el código o parearlo con un Tag</small>

        <div v-for="(uuid, index) in selectedItem.assetuuid" :key="uuid" class="card mt-2 d-flex flex-column align-items-center">
          <!-- Aseguramos que todo dentro del modal use la clase modal-content-wrapper para aislar los estilos -->
          <div class="card-body text-center modal-content-wrapper">
            <!-- Mostrar el texto "Código QR" arriba del código QR -->
            <h5 class="mt-3">Código QR:</h5>
            
            <!-- Mostrar el código QR -->
            <vue-qrcode :id="'qr-code-' + uuid" :text="generateQRCodeContent(uuid)" :size="200"></vue-qrcode>

            <!-- Botón de Imprimir QR alineado correctamente debajo del QR -->
            <div class="mt-2">
              <b-button @click="printQRCode(uuid)" variant="warning">
                <i class="fa fa-print"></i> Imprimir QR
              </b-button>
            </div>

            <!-- Mostrar el código de barras GTIN debajo del QR -->
            <h5 class="mt-4">Código de Barras GTIN:</h5>
            <svg :id="'barcode-' + uuid"></svg>
            
            <!-- Botón de Imprimir GTIN alineado debajo del código de barras -->
            <div class="mt-2">
              <b-button @click="printGTIN(uuid)" variant="warning">
                <i class="fa fa-print"></i> Imprimir GTIN
              </b-button>
            </div>

            <!-- Botón para iniciar el pareo con Tag -->
            <div class="mt-3 text-center">
              <b-button @click="startPairingProcess(index)" variant="primary" class="w-100">
                Parear con Tag
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Contenido de pareo con Tag (se muestra cuando selectCameraMode es true) -->
      <div v-show="selectCameraMode" class="text-center">
        <small>Escanea el código QR o lee el código de barras GTIN</small>
        <!-- Selector de cámara -->
        <div class="text-center mb-2 mt-2">
          <label for="cameraSelection">Seleccionar Cámara:</label>
          <select id="cameraSelection" @change="changeCamera" v-model="selectedCameraId" class="form-control d-inline-block w-auto">
            <option v-for="device in videoDevices" :key="device.id" :value="device.id">
              {{ device.label || `Cámara ${device.id}` }}
            </option>
          </select>
        </div>
        <!-- Lector QR -->
        <div id="qr-reader" class="video-fluid mt-2"></div>
        <!-- Botón para cancelar el pareo -->
        <div class="mt-3 text-center">
          <b-button @click="cancelPairing" variant="secondary" class="w-100">
            Cancelar
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import vuejsDatepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/dist/locale';
import VueQrcodeComponent from 'vue-qrcode-component';
import Config from "@/../config.json";
import JsBarcode from 'jsbarcode';
import { Html5Qrcode, Html5QrcodeScannerState, Html5QrcodeSupportedFormats } from "html5-qrcode";

export default {
  components: {
    vuejsDatepicker,
    VueQrcode: VueQrcodeComponent
  },
  data() {
    return {
      isScanning: false,
      photo: null,
      preview: null,
      fileName: null,
      selectedTab: 0,
      invoiceData: [],
      tableColumns: ['Dispositivo', 'Cantidad', "SN", "LN", "FV"],
      es,
      loading: false,
      showModal: false,
      selectedItem: {},
      usingFrontCamera: true,  // Nueva propiedad para gestionar la cámara frontal/trasera
      videoDevices: [],  // Lista de dispositivos de video
      selectedVideoSource: '',  // Dispositivo de video seleccionado
      listuuid: localStorage.getItem('listuuid') || this.generateUUID(),
      tagId: null, // Almacena el id del tag escaneado,
      selectedCameraId: null, // Almacena el ID de la cámara seleccionada por el usuario
      qrCodeScanner: null, // Objeto del escáner de QR
      selectCameraMode: false,  // Nueva propiedad para controlar si se muestra el selector de cámara
      cameraStream: null,  // Aquí se almacenará el stream de la cámara
      currentPairingIndex: null,
      spinnerImage: require('@/assets/an_spinner_transparent.gif')
    };
  },
  methods: {
    goToMyAssetList() {
      this.$router.push({ name: 'MyAssetList' });
    },
    async updateListUUID() {
      try {
        const token = localStorage.getItem('token');

        const uuidResponse = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/getUserLastUUID`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (uuidResponse.ok) {
          const uuidData = await uuidResponse.json();
          if (this.listuuid !== uuidData.last_uuid) {
            this.listuuid = uuidData.last_uuid;
            localStorage.setItem('listuuid', this.listuuid);
            this.fetchAssetList(); // Actualizar la lista de activos
          }
        }
      } catch (error) {
        console.error('Error updating list UUID:', error);
      }
    },
    onModalClose() {
      console.log("Modal cerrado, deteniendo escaneo de QR...");
      if (this.qrCodeScanner) {
        this.qrCodeScanner.stop().then(() => {
          this.qrCodeScanner.clear();
          this.qrCodeScanner = null;
          document.getElementById("qr-reader").innerHTML = "";  // Limpia el lector QR
          console.log("Escáner QR detenido correctamente.");
        }).catch((err) => {
          console.error("Error al detener el escáner QR:", err);
        });
      }

      this.stopCamera();  // Detener la cámara si está activa
      this.currentPairingIndex = null; // Resetear el índice de pareo
    },
    // Modificar startPairingProcess para evitar múltiples escaneos
    async startPairingProcess(index) {
      console.log("Iniciando proceso de pareo para el índice: " + index);

      // Detenemos cualquier cámara que esté activa
      this.stopCamera();

      // Mostrar el modo de selección de cámara
      this.selectCameraMode = true;
      
      // Guardamos el índice actual del pareo
      this.currentPairingIndex = index;

      await this.$nextTick(); // Esperar a que el DOM se actualice

      // Obtener cámaras disponibles
      await this.getAvailableCameras();

      // Seleccionar la primera cámara si no hay ninguna seleccionada
      if (!this.selectedCameraId && this.videoDevices.length > 0) {
        this.selectedCameraId = this.videoDevices[0].id;
        console.log("Cámara predeterminada seleccionada:", this.selectedCameraId);
      }

      // Iniciar la cámara para el escaneo
      if (this.selectedCameraId) {
        this.startPairingCamera();
      } else {
        console.error("No hay cámara seleccionada.");
      }
    },
    cancelPairing() {
      console.log("Cancelando proceso de pareo");
      this.selectCameraMode = false; // Ocultar el selector de cámara y el video
      this.stopCamera(); // Detener la cámara
    },

    isImage(file) {
      return file.startsWith('data:image/');
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.preview = event.target.result;
          this.fileName = file.name;
          this.fileData = event.target.result; // Asignar el contenido del archivo a this.fileData
        };
        reader.readAsDataURL(file); // Leer el archivo como Data URL
      }
    },
    async startCamera() {
      try {
        const constraints = {
          video: {
            deviceId: this.selectedCameraId ? { exact: this.selectedCameraId } : undefined
          }
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.cameraStream = stream;
        
        this.$nextTick(() => {
          if (this.$refs.video) {
            this.$refs.video.srcObject = stream;
          } else {
            console.error("Referencia al video no disponible.");
          }
        });
      } catch (error) {
        console.error('Error al iniciar la cámara: ', error);
      }
    },

    // Método para detener la cámara
    stopCamera() {
      if (this.cameraStream) {
        this.cameraStream.getTracks().forEach(track => track.stop());
        this.cameraStream = null;
      }
    },
    async getDevices() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.videoDevices = devices.filter(device => device.kind === 'videoinput');

        if (this.videoDevices.length > 0) {
          this.selectedVideoSource = this.videoDevices[0].deviceId;
        } else {
          console.warn('No se encontraron dispositivos de video.');
        }
      } catch (error) {
        console.error('Error al obtener las cámaras: ', error);
      }
    },
    async getStream() {
      try {
        // Obtener el stream de la cámara seleccionada
        const constraints = {
          video: {
            deviceId: this.selectedVideoSource ? { exact: this.selectedVideoSource } : undefined
          }
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        this.cameraStream = stream;

        // Asegúrate de que el ref del video esté disponible
        this.$nextTick(() => {
          if (this.$refs.video) {
            this.$refs.video.srcObject = stream;
          } else {
            console.error("Referencia al video no disponible.");
          }
        });

        return stream;
      } catch (error) {
        console.error("Error al obtener el stream: ", error);
      }
    },

    takePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      
      // Ajustar las dimensiones del canvas a las dimensiones reales del video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Dibujar la imagen del video en el canvas con la mejor calidad
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      
      // Convertir el contenido del canvas a imagen en formato PNG de alta resolución
      this.preview = canvas.toDataURL('image/png', 1.0); // '1.0' asegura la mejor calidad
      
      // Asignar el contenido de la imagen capturada a this.fileData
      this.fileData = this.preview;

      // Asignar un nombre al archivo
      this.fileName = 'captured_image_high_res.png';
      
      // Llamar a la función para subir el archivo
      this.uploadFile();
    },
    // Método para subir el archivo
    async uploadFile() {
      this.loading = true; // Inicia la carga, muestra el spinner
      try {
        // Preparar los datos para enviar al servidor
        const formData = {
          file: this.fileData,  // Este es el contenido del archivo
          file_name: this.fileName  // Este es el nombre del archivo
        };

        const token = localStorage.getItem('token');
        
        const response = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/upload`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(formData)  // Enviar el archivo al servidor
        });

        if (response.ok) {
          const result = await response.json();
          this.preview = result.preview;  // Vista previa
          this.loading = false;  // Detiene la carga
        } else {
          // Manejar el error en caso de que falle el endpoint
          const errorResult = await response.json();
          console.error('Error uploading file:', errorResult.error);
          this.loading = false;  // Detiene la carga
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        this.loading = false;  // Detiene la carga
      }
    },
    async fetchAssetList() {
      this.loading = true;
      try {
        // Obtener el token JWT de localStorage
        const token = localStorage.getItem('token');

        // Obtener el último UUID del usuario
        const uuidResponse = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/getUserLastUUID`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (uuidResponse.ok) {
          const uuidData = await uuidResponse.json();
          this.listuuid = uuidData.last_uuid;
        } else {
          // Si no hay un UUID previo, generar uno nuevo
          this.listuuid = this.generateUUID();
        }

        localStorage.setItem('listuuid', this.listuuid);

        const response = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/getassetlist`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`  // Enviar el token como Bearer
          },
          body: JSON.stringify({ uuid: this.listuuid })
        });

        if (response.ok) {
          const result = await response.json();
          this.invoiceData = result.assetList.map(item => ({ ...item, editing: false }));
        } else if (response.status === 404) {
          // Si la lista no existe, inicializar invoiceData vacío
          this.invoiceData = [];
        } else {
          console.error('Error fetching asset list:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching asset list:', error);
      } finally {
        this.loading = false;
      }
    },
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      return `${String(d.getDate()).padStart(2, '0')}-${String(d.getMonth() + 1).padStart(2, '0')}-${d.getFullYear()}`;
    },
    editDate(item) {
      item.editing = true;
    },
    validateQuantity(item) {
      if (item.Cantidad === 0 || !item.Cantidad) {
        alert('La cantidad debe ser 1 o más.');
      }
      if (item.Cantidad > 1) {
        item.SN = '';
      }
    },
    isComplete(item) {
      return item.Dispositivo && item.Cantidad && (item.Cantidad === 1 || item.LN);
    },
    canSave(item) {
      return item.Dispositivo && item.Cantidad && (item.Cantidad === 1 || item.LN);
    },
    editItem(item) {
      item.editing = true;
    },
    saveItem(item) {
      if (!this.canSave(item)) {
        alert('Por favor, complete todos los campos obligatorios.');
        return;
      }
      item.editing = false;
    },
    async enrollItem(item, index) {
      const quantity = parseInt(item.Cantidad, 10);
      const assetuuidArray = Array.from({ length: quantity }, () => this.generateUUID());
      const updatedItem = { ...item, assetuuid: assetuuidArray, ownerId: 'owner-id-example' }; // Asigna el ownerId aquí
      try {
        // Obtener el token JWT de localStorage
        const token = localStorage.getItem('token');
        const response = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/enrollassets`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`  // Enviar el token como Bearer
          },
          body: JSON.stringify({ listuuid: this.listuuid, asset: updatedItem })
        });
        const result = await response.json();
        this.$set(this.invoiceData, index, { ...updatedItem, editing: false });
        alert('Item enrolled successfully!');
      } catch (error) {
        console.error('Error enrolling item:', error);
      }
    },
    showDetails(item) {
      this.selectedItem = item;
      this.showModal = true;

      // Esperar a que el modal esté completamente renderizado
      this.$nextTick(() => {
        // Generar los códigos de barras después de que el modal esté listo
        this.generateBarcode(item);
      });
    },
    async deleteItem(index) {
      try {
        // Obtener el token JWT de localStorage
        const token = localStorage.getItem('token');
        await fetch(`${Config.TRAZAMED_APP_URL_BASE}/deleteAssets`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`  // Enviar el token como Bearer
          },
          body: JSON.stringify({ uuid: this.listuuid, index })
        });
        this.invoiceData.splice(index, 1);
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    },
    addNewItem() {
      const newItem = {
        Dispositivo: '',
        Cantidad: '',
        SN: '',
        LN: '',
        FV: '',
        editing: true,
        paired: false,  // Inicializa esta propiedad
        assetuuid: null
      };
      this.invoiceData.push(newItem);
    },
    clearTable() {
      this.invoiceData = [];
      this.preview = null;
      this.fileName = null;
    },
    toggleCamera() {
      this.usingFrontCamera = !this.usingFrontCamera;
      this.startCamera();  // Reiniciar la cámara con la nueva configuración
    },
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    generateQRCodeContent(uuid) {
      const item = this.selectedItem;
      return JSON.stringify({
        [uuid]: {
          Dispositivo: item.Dispositivo,
          SN: item.SN,
          LN: item.LN,
          FV: this.formatDate(item.FV),
          wiliotAssetId: ""
        }
      });
    },
    generateGeneralQRCodeContent() {
      return JSON.stringify({ listuuid: this.listuuid });
    },
    createNewAnexo() {
      localStorage.removeItem('listuuid');
      this.clearTable();
      this.$router.push('/');
    },
    generateGTINFromUUID(uuid) {
      if (typeof uuid !== 'string') {
        console.error("UUID is not a string:", uuid);
        return ''; // Retorna una cadena vacía o un valor por defecto en caso de error
      }
      
      let baseGTIN = uuid.replace(/[^0-9]/g, '').slice(0, 12); // Limpiar el uuid para que solo tenga números
      baseGTIN = baseGTIN.padEnd(12, '0'); // Rellenar con ceros si es necesario
      const checkDigit = this.calculateCheckDigit(baseGTIN);
      return baseGTIN + checkDigit; // Devuelve el GTIN completo
    },
    calculateCheckDigit(baseGTIN) {
      let sum = 0;
      for (let i = 0; i < baseGTIN.length; i++) {
        let digit = parseInt(baseGTIN[i]);
        sum += i % 2 === 0 ? digit * 3 : digit;
      }
      let remainder = sum % 10;
      return remainder === 0 ? 0 : 10 - remainder;
    },

    getGTINForAsset(uuid) {
      // Generar el GTIN usando el UUID o cualquier otro campo que prefieras
      return this.generateGTINFromUUID(uuid);
    },
    generateBarcode(item) {
      if (!Array.isArray(item.assetuuid) || item.assetuuid.length === 0) {
        console.error("Invalid assetuuid for barcode generation:", item.assetuuid);
        return;
      }

      // Itera sobre cada UUID del array
      item.assetuuid.forEach(uuid => {
        const gtin = this.generateGTINFromUUID(uuid); // Generar GTIN usando cada UUID
        const svg = document.getElementById(`barcode-${uuid}`);

        if (!svg) {
          console.error("SVG element not found for UUID:", uuid);
          return;
        }

        // Usa el formato CODE128, que admite cadenas largas
        JsBarcode(svg, gtin, {
          format: "CODE128",
          lineColor: "#000",
          width: 2,
          height: 100,
          displayValue: true
        });
      });
    },
    // Modificar pairWithTag para que se llame solo una vez al endpoint de pareo
    async pairWithTag(uuidArray, item, tagId) {
      try {
        // Verificar que currentPairingIndex está definido
        if (this.currentPairingIndex === null) {
          console.error('currentPairingIndex no está definido');
          return;
        }

        const uuid = uuidArray[this.currentPairingIndex];
        if (!item) {
          console.error('El item no está definido');
          return;
        }

        // Evitar múltiples pareos
        if (item.pairing) {
          console.warn('Ya se está pareando este item.');
          return;
        }

        // Marcar el item como en proceso de pareo
        this.$set(item, 'pairing', true);

        const token = localStorage.getItem('token');
        const payload = {
          token,
          asset_id: uuid,
          asset_name: item.Dispositivo,
          asset_categoryId: "986c5691-df49-41e9-9d40-3824bcf88205", // Ajusta este valor si es necesario
          asset_tagId: tagId,
        };

        const response = await fetch('https://ynb117yyh5.execute-api.us-east-2.amazonaws.com/v1/create_new_asset', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload),
          timeout: 20000
        });

        if (response.ok) {
          item.paired = true; // Marcar como pareado exitosamente
          item.error = false; // No hay error, estado correcto
          this.$bvToast.toast("Dispositivo pareado correctamente al Tag", {
            title: "Éxito",
            solid: true,
            variant: "success",
            autoHideDelay: 5000
          });

          // Llamar al endpoint de pareo con el backend
          const pairAssetResponse = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/pairAsset`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              assetuuid: uuid,
              wiliot_id: tagId,
              details: {
                Dispositivo: item.Dispositivo,
                SN: item.SN,
                LN: item.LN,
                FV: this.formatDate(item.FV)
              }
            })
          });

          if (pairAssetResponse.ok) {
            this.$bvToast.toast("Activo registrado en el sistema correctamente", {
              title: "Éxito",
              solid: true,
              variant: "success",
              autoHideDelay: 5000
            });
          } else {
            const errorResult = await pairAssetResponse.json();
            console.error('Error registrando el activo en el backend:', errorResult.error);
            this.$bvToast.toast("Error registrando el activo en el sistema", {
              title: "Error",
              solid: true,
              variant: "danger",
              autoHideDelay: 5000
            });
          }
        } else {
          throw new Error("Problema al asociar el Tag");
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
        item.paired = false; // Mantener el estado de no pareado
        item.error = true;  // Marcar que hubo un error
        this.$bvToast.toast("Problema al asociar el Tag", {
          title: "Error",
          solid: true,
          variant: "danger",
          autoHideDelay: 5000
        });
      } finally {
        item.pairing = false;
        this.selectCameraMode = false;
        this.stopCamera();
        this.currentPairingIndex = null; // Resetear el índice de pareo
      }
    },
    // Función para iniciar la cámara
    openCamera() {
      return new Promise((resolve, reject) => {
        // Verifica si ya se ha inicializado el escáner QR
        if (!this.qrCodeScanner) {
          this.qrCodeScanner = new Html5Qrcode("qr-reader");
        }

        const config = { fps: 10, qrbox: 250 };
        this.qrCodeScanner.start(
          this.selectedCameraId, // Usamos la cámara seleccionada por el usuario
          config,
          (decodedText) => {
            resolve(decodedText); // Retornamos el código QR escaneado
            this.qrCodeScanner.stop().then(() => {
              document.getElementById("qr-reader").innerHTML = ""; // Limpiar la vista previa
            }).catch((err) => {
              console.error("Error al detener el escaneo de QR:", err);
            });
          },
          (errorMessage) => {
            console.warn(`Error de escaneo: ${errorMessage}`);
          }
        ).catch((err) => {
          console.error("Error abriendo la cámara:", err);
          reject(err);
        });
      });
    },
    // Función para escanear el QR y devolver el tagId
    // Función para escanear el QR y devolver el tagId
    async scanQRCode() {
      this.stopCamera();  // Asegurarse de detener la cámara antes de iniciar el escaneo
      return new Promise((resolve, reject) => {
        const html5QrCode = new Html5Qrcode("qr-reader");  // ID del div que usará html5-qrcode
        const config = { fps: 10, qrbox: 250 };

        html5QrCode.start(
          this.selectedCameraId,  // Usa la cámara seleccionada
          config,
          (decodedText) => {
            console.log("QR Code escaneado: ", decodedText);
            resolve(decodedText);  // Devuelve el código QR escaneado
            html5QrCode.stop().then(() => {
              document.getElementById("qr-reader").innerHTML = "";  // Limpia el lector QR
              this.selectCameraMode = false;  // Oculta el modo de cámara
              this.$nextTick(() => {
                this.showDetails(this.selectedItem);  // Muestra los detalles de los activos
              });
            }).catch((err) => console.error("Error deteniendo el lector QR:", err));
          },
          (errorMessage) => {
            console.warn("Error de escaneo: ", errorMessage);
          }
        ).catch((err) => {
          console.error("Error iniciando el lector QR: ", err);
          reject(err);
        });
      });
    },
    async getAvailableCameras() {
      try {
        const devices = await Html5Qrcode.getCameras();
        this.videoDevices = devices;

        console.log('Cámaras obtenidas:', this.videoDevices);

        if (this.videoDevices.length > 0 && !this.selectedCameraId) {
          this.selectedCameraId = this.videoDevices[0].id;
          console.log("Cámara predeterminada seleccionada:", this.selectedCameraId);
        }
      } catch (error) {
        console.error("Error obteniendo cámaras:", error);
      }
    },
    async startPairingCamera() {
      try {
        if (!this.selectedCameraId) {
          console.error('No se ha seleccionado ninguna cámara.');
          return;
        }

        await this.$nextTick();

        const qrReaderElement = document.getElementById("qr-reader");
        if (!qrReaderElement) {
          console.error("El elemento 'qr-reader' no existe en el DOM.");
          return;
        }

        if (this.qrCodeScanner) {
          const scannerState = this.qrCodeScanner.getState();
          if (scannerState === Html5QrcodeScannerState.SCANNING || scannerState === Html5QrcodeScannerState.PAUSED) {
            await this.qrCodeScanner.stop().catch(err => console.error("Error deteniendo el escáner:", err));
          }
          this.qrCodeScanner.clear();
          this.qrCodeScanner = null;
        }

        qrReaderElement.innerHTML = "";

        this.qrCodeScanner = new Html5Qrcode("qr-reader");

        const config = { 
          fps: 10, 
          qrbox: 250,
          formatsToSupport: [
            Html5QrcodeSupportedFormats.QR_CODE,
            Html5QrcodeSupportedFormats.CODE_128,
            Html5QrcodeSupportedFormats.CODE_39,
            Html5QrcodeSupportedFormats.EAN_13,
            // Otros formatos si es necesario
          ]
        };

        await this.qrCodeScanner.start(
          this.selectedCameraId,
          config,
          async (decodedText, decodedResult) => {
            console.log("Código escaneado: ", decodedText);
            console.log("Formato del código: ", decodedResult.result.format.formatName);

            // Procesar el código escaneado según su formato
            await this.pairWithTag(this.selectedItem.assetuuid, this.selectedItem, decodedText);

            await this.qrCodeScanner.stop();
            this.qrCodeScanner.clear();
            this.qrCodeScanner = null;
            qrReaderElement.innerHTML = "";
            this.selectCameraMode = false;
          },
          (errorMessage) => {
            console.warn("Error de escaneo: ", errorMessage);
          }
        );
      } catch (err) {
        console.error("Error iniciando la cámara de pareo:", err);
      }
    },

    async changeCamera() {
      if (!this.selectedCameraId) {
        console.error("selectedCameraId es undefined al intentar cambiar la cámara.");
        return;
      }

      console.log("Cambiando a la cámara con ID:", this.selectedCameraId);

      // Detener y limpiar el escáner actual si está en ejecución
      if (this.qrCodeScanner) {
        const scannerState = this.qrCodeScanner.getState();
        if (scannerState === Html5QrcodeScannerState.SCANNING || scannerState === Html5QrcodeScannerState.PAUSED) {
          await this.qrCodeScanner.stop().catch(err => console.error("Error deteniendo el escáner:", err));
        }
        this.qrCodeScanner.clear();
        this.qrCodeScanner = null;
      }

      await this.$nextTick();

      // Iniciar el escáner con la nueva cámara
      this.startPairingCamera();
    },
    // Función para imprimir el QR
    printQRCode(uuid) {
      const qrElement = document.getElementById(`qr-code-${uuid}`); // Asegúrate de que esta ID esté asociada al QR específico

      if (!qrElement) {
        alert("QR Code no encontrado.");
        return;
      }

      const printWindow = window.open("", "PRINT", "height=600,width=800");

      printWindow.document.write("<html><head><title>Código QR</title>");
      printWindow.document.write("</head><body>");
      printWindow.document.write(qrElement.outerHTML); // Copiamos el HTML del QR al nuevo documento
      printWindow.document.write("</body></html>");

      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    },
    // Función para imprimir el GTIN
    printGTIN(uuid) {
      const gtinElement = document.getElementById(`barcode-${uuid}`);
      if (!gtinElement) {
        console.error('Código GTIN no encontrado.');
        alert('Código GTIN no encontrado.');
        return;
      }

      // Crear una nueva ventana con el contenido del GTIN
      const printWindow = window.open('', '_blank', 'width=600,height=400');
      printWindow.document.write('<html><head><title>Imprimir GTIN</title></head><body>');
      printWindow.document.write(gtinElement.outerHTML); // Agregar el GTIN al contenido
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    },
  },
  created() {
    this.fetchAssetList();

    // Intervalo para actualizar el UUID cada 5 minutos (300000 ms)
    this.uuidInterval = setInterval(() => {
      this.updateListUUID();
    }, 10000); // 10 segundos
  },
  watch: {
    invoiceData: {
      handler() {
        this.$nextTick(() => {
          this.generateGeneralQRCodeContent();
        });
      },
      deep: true
    },
    selectedTab(newVal) {
      if (newVal === 1) {
        this.getDevices();
        this.$nextTick(() => {
          this.startCamera();
        });
      }
    },
    selectedVideoSource() {
      this.getStream();
    },
    showModal(newValue) {
      if (!newValue) {
        this.selectCameraMode = false;
        if (this.qrCodeScanner) {
          const scannerState = this.qrCodeScanner.getState();
          if (scannerState === Html5QrcodeScannerState.SCANNING || scannerState === Html5QrcodeScannerState.PAUSED) {
            this.qrCodeScanner.stop().then(() => {
              this.qrCodeScanner.clear();
              this.qrCodeScanner = null;
              document.getElementById("qr-reader").innerHTML = "";
            }).catch((err) => console.error("Error deteniendo el escáner QR:", err));
          } else {
            this.qrCodeScanner.clear();
            this.qrCodeScanner = null;
            document.getElementById("qr-reader").innerHTML = "";
          }
        }
        this.stopCamera();
      }
    },
    selectCameraMode(newValue) {
      if (!newValue) {
        if (this.qrCodeScanner) {
          const scannerState = this.qrCodeScanner.getState();
          if (scannerState === Html5QrcodeScannerState.SCANNING || scannerState === Html5QrcodeScannerState.PAUSED) {
            this.qrCodeScanner.stop().then(() => {
              this.qrCodeScanner.clear();
              this.qrCodeScanner = null;
              document.getElementById("qr-reader").innerHTML = "";
            }).catch((err) => console.error("Error deteniendo el escáner QR:", err));
          } else {
            this.qrCodeScanner.clear();
            this.qrCodeScanner = null;
            document.getElementById("qr-reader").innerHTML = "";
          }
        }
        this.stopCamera();
      }
    },
    /*
    selectedCameraId(newValue) {
      console.log('selectedCameraId ha cambiado a:', newValue);
      if (newValue) {
        this.startPairingCamera(); // Inicia la cámara solo después de seleccionar una
      }
    }
    */
  },
  beforeDestroy() {
    clearInterval(this.uuidInterval);
    // Limpiar el escáner si existe
    if (this.qrCodeScanner) {
      this.qrCodeScanner.clear();
      this.qrCodeScanner = null;
    }
    this.stopCamera();  // Detener la cámara cuando el componente se destruya
    // Detener la cámara cuando el componente se destruya
    if (this.cameraStream) {
      this.cameraStream.getTracks().forEach(track => track.stop());
    }
  }
};
</script>

<style scoped>
/* Estilos personalizados globales */
.content {
  margin-top: 20px;
}
.form-group {
  padding: 20px;
}
.video-fluid {
  width: 100%;
  height: auto;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.pdf-preview {
  width: 100%;
  height: auto;
}
.card-title, .card-category {
  text-align: center;
}
.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form-check-input {
  margin-right: 10px;
}
.form-check-label {
  flex: 1;
  text-align: left;
}
.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}
.qr-general {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

/* Estilos para ajustar la apariencia del modal y los códigos */
.d-flex {
  display: flex;
  align-items: center;
}
.ml-3 {
  margin-left: 1rem;
}

/* Estilos específicos para el modal */
.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content-wrapper h5 {
  margin-bottom: 10px;
}

.modal-content-wrapper .mt-2 {
  margin-top: 10px;
}

.modal-content-wrapper .mt-4 {
  margin-top: 20px;
}

.modal-content-wrapper .btn {
  margin-top: 10px;
  width: 150px;  /* Ancho específico solo para los botones dentro del modal */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;  /* Ajusta la altura según sea necesario */
}

.spinner-container img {
  width: 300px;  /* Ajusta el tamaño del spinner */
  height: 300px;
}


</style>


